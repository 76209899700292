import * as React from 'react'
import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'

import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Button from 'react-bootstrap/Button'

import Layout from '@components/layout'

const InspectionPage = () => {
  return (
    <Layout pageTitle="Inspections">
      <Container>
        <Row>
          <Col>
            <div className="hero-banner large-hero light-hero">
              <StaticImage
                className="hero-image"
                src="../../../media/wessuc_default_hero.png"
                alt=""
                aspectRatio={3 / 1}
                layout="fullWidth" />
              <div className="hero-text">
                <h2>Services</h2>
                <h1>Inspection &amp; Repair</h1>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
      <main>
        <Container fluid="xxl" className="section default-text">
          <Row md={2} xs={1} className="align-items-center">
            <Col md={{ order: 'first' }} xs={{ order: 'last' }}>
              <StaticImage
                src="../../../media/grouting3.png"
                alt="a metal pulley lowering a wire line into an open manhole"
              />
            </Col>
            <Col>
              <h3>Spot Repair</h3>
              <p>
                A spot repair is used when the integrity of a pipe is compromised by a defect. Applying a spot repair will increase the longevity of a pipe, and lower its risk of failure. Spot repair goes hand in hand with cutting and grouting.
              </p>
              <Link to="spot-repair">
                <Button variant="primary" className="shadow">Learn More</Button>
              </Link>
            </Col>
          </Row>
        </Container>
        <Container fluid="xxl" className="section default-text">
          <Row md={2} xs={1} className="align-items-center">
            <Col>
              <h3>Grouting &amp; Cutting</h3>
              <p>
                Grouting is the use of a chemical sealant to stop leaks and infiltration from coming into a pipe. Grout not only stops infiltration but is also effective to prevent erosion around the outside of the pipe.
              </p>
              <p>
                Grouting is a much cheaper option than treating ground water and less risks of of backup due to overwhelming pump stations etc. 
              </p>
              <Link to="grouting-and-cutting">
                <Button variant="primary" className="shadow">Learn More</Button>
              </Link>
            </Col>
            <Col>
              <StaticImage
                src="../../../media/grouting1.png"
                alt="a grouting crew set up gear out the back of their cube van"
              />
            </Col>
          </Row>
        </Container>
        <Container fluid="xxl" className="section default-text">
          <Row md={2} xs={1} className="align-items-center">
            <Col md={{ order: 'first' }} xs={{ order: 'last' }}>
              <StaticImage
                src="../../../media/cctv-crawler.png"
                alt="a small robotic camera crawler for filming through sewers"
              />
            </Col>
            <Col>
              <h3>CCTV &amp; Flushing</h3>
              <p>
                CCTV (Closed Circuit Television) is a non-intrusive method of inspecting pipes and sewers without having to dig anything up to do it. Wessuc uses these remote controlled units to search for any signs of damage or blockage
              </p>
              <p>
                Wessuc’s advanced flushing trucks provided high pressure cleaning and vacuuming of wastewater collection systems, storm water drainage systems, lift stations, pump stations, manholes, catch basins, and more.
              </p>
              <Link to="cctv-and-flushing">
                <Button variant="primary" className="shadow">Learn More</Button>
              </Link>
            </Col>
          </Row>
        </Container>
      </main>
    </Layout>
  )
}

export default InspectionPage